<template>
	<div class="app-content content auth-page">
		<div class="content-wrapper" style="padding: 0 !important">
			<router-view/>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	created() {
		$(function(){
			$('body').removeClass('vertical-layout vertical-menu-modern 2-columns navbar-floating footer-static  pace-done')
			$('body').addClass('vertical-layout vertical-menu-modern 1-column navbar-floating footer-static blank-page blank-page')
			$('body').removeAttr('data-col')
		})
	}, 
	beforeDestroy() {
		$(function(){
			$('body').removeClass('vertical-layout vertical-menu-modern 1-column navbar-floating footer-static blank-page blank-page')
			$('body').addClass('vertical-layout vertical-menu-modern 2-columns navbar-floating footer-static  pace-done data-col')
			$('body').attr('data-col')
		})
	}
}
</script>